import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'gatsby';

import GenericIcon from '../GenericIcon';
import { ReactComponent as HostJaneIcon } from './hostjane-default-flame.svg';
import { ReactComponent as PaymentShieldIcon } from './payment-protection.svg';
import { ReactComponent as CheckIcon } from '../../svgs/icons/check.svg';
import { ReactComponent as TwitterLogo } from './Twitter_Logo_WhiteOnBlue.svg';
import { ReactComponent as ChevronDownIcon } from '../../svgs/icons/chevron-down.svg';

export const Article = styled.article`
	color: #222;
	line-height: 1.6;
	font-size: 16px;
	p {
		// margin: 0 0 20px 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.hostjane-red {
		color: #e41937;
		&:hover {
			color: #e41937;
		}
	}
	.hostjane-blue {
		color: #0052cc;
		&:hover {
			color: #0052cc;
		}
	}

	a {
		color: #222;
		text-decoration: none;
		border-bottom: 1px solid #222;
		&:hover {
			color: #222;
			text-decoration: none;
		}
	}
`;

const HostJaneFlameContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	padding: 0;
	margin: 0 0 25px 0;

	.hostjane-flame__icon {
		height: 46px;
		width: 46px;
		flex: 0 0 46px;
		border: 1px solid #9da3a6;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f7f9fa;
		font-size: 15px;
		font-weight: bold;
		margin: 0 15px 0 0;

		svg {
			height: 28px;
			width: 28px;
		}
	}

	.hostjane-flame__content {
		padding: 5px 0 0 0;
		flex: 1 1 auto;
		p {
			margin-top: 0;
		}
	}
`;
export function HostJaneFlame({ children }) {
	return (
		<HostJaneFlameContainer>
			<div className="hostjane-flame__icon">
				<HostJaneIcon />
			</div>
			<Article className="hostjane-flame__content">{children}</Article>
		</HostJaneFlameContainer>
	);
}

const StyledListUl = styled.ul`
	margin: 0 0 20px 0;
	&:last-child {
		margin-bottom: 0;
	}
	list-style: none;
	padding: 0 0 0 5px;

	.hostjane-styledlist__li {
		display: flex;
		align-items: flex-start;
		flex-flow: row nowrap;
	}
	.hostjane-styledlist__icon {
		margin: 0 10px 0 0;
		flex: 0 0 auto;
		height: ${16 * 1.6}px;
		display: flex;
		align-items: center;
	}
`;
export function StyledList({ items, className = null }) {
	return (
		<StyledListUl className={classNames('hostjane-styledlist', className)}>
			{items.map(item => (
				<li className="hostjane-styledlist__li" key={item}>
					<GenericIcon className="hostjane-styledlist__icon">
						<CheckIcon />
					</GenericIcon>
					<span className="hostjane-styledlist__text">{item}</span>
				</li>
			))}
		</StyledListUl>
	);
}

const TwitterBlockContainer = styled.div`
	background-color: #1da1f2;
	color: #fff;
	padding: 10px 20px;
	a.hostjane-twitter-block__anchor {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		color: #fff;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	.hostjane-twitter-block__icon {
		height: 48px;
		width: 48px;
	}
	.hostjane-twitter-block__profile {
		border-bottom: 1px solid #fff;
	}
`;
export function TwitterBlock({ profile }) {
	return (
		<TwitterBlockContainer className="hostjane-twitter-block">
			<a
				href={`https://x.com/${profile}`}
				className="hostjane-twitter-block__anchor"
			>
				<div className="hostjane-twitter-block__icon">
					<TwitterLogo />
				</div>
				<div className="hostjane-twitter-block__text">
					Follow @
					<span className="hostjane-twitter-block__profile">
						{profile}
					</span>{' '}
					for more updates.
				</div>
			</a>
		</TwitterBlockContainer>
	);
}

const SingleItemContainer = styled.aside`
	width: 100%;
	flex: 0 0 100%;
	padding: 15px;
	border-top: 1px solid #d4d9dc;
	&:last-child {
		border-bottom: 1px solid #d4d9dc;
	}

	.hostjane-hiw-item__header {
		display: flex;
		flex-flow: row nowrap;
		color: #000;
		align-items: center;
	}
	.hostjane-hiw-item__headericon {
		display: none;
	}
	.hostjane-hiw-item__count {
		height: 46px;
		width: 46px;
		border: 1px solid #9da3a6;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f7f9fa;
		font-size: 18px;
		font-weight: bold;
		margin: 0 15px 0 0;
		svg {
			height: 28px;
			width: 28px;
		}
	}
	.hostjane-hiw-item__title {
		font-size: 20px;
		line-height: 1.25;
		font-weight: 800;
	}
	.hostjane-hiw-item__chevvy {
		font-size: 22px;
		margin-left: auto;
		height: 22px;
		width: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: transform 200ms ease-out;

		&.hostjane-hiw-item__chevvy--open {
			transform: rotate(180deg);
		}
	}

	.hostjane-hiw-item__body {
		display: none;
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
		flex-flow: column nowrap;
		padding: 0 10px 0 60px;
		font-size: 17px;
		color: #2c2e2f;
		.hostjane-hiw-item__icon {
			margin-left: auto;
			margin-right: auto;
			align-self: center;
			transform: translateX(-30px);
		}
	}
	.hostjane-hiw-item__body--open {
		display: flex;
	}

	.hostjane-hiw-item__icon {
		width: 190px;
		height: auto;
	}
	.hostjane-hiw-item__content {
		line-height: 1.6;
		font-size: 18px;
		margin: 0;
		padding-bottom: 15px;
		color: #2c2e2f;
	}
	.hostjane-hiw-item__link {
		// display: block;
		color: #2c2e2f;
		padding: 15px 0 2px 0;
		border-bottom: 1px solid rgba(44, 46, 47, 0.3);
		text-decoration: none;
		transition: border-color 200ms ease-out;
		&:hover {
			text-decoration: none;
			border-bottom: 1px solid rgba(44, 46, 47, 1);
		}
	}

	/** Adjustments for tablet */
	@media screen and (min-width: 1024px) {
		border-top: 0 none;
		border-bottom: 0 none;
		&:last-child {
			border-bottom: 0 none;
		}
		width: ${props => (props.fullWidth ? '100%' : '200px')};
		padding: 0 14px;
		flex: 1 0 ${props => (props.fullWidth ? '100%' : '200px')};
		max-width: ${props => (props.fullWidth ? '100%' : '320px')};
		margin-bottom: ${props => (props.fullWidth ? '20px' : '0')};

		.hostjane-hiw-item__header {
			flex-flow: row wrap;
		}
		.hostjane-hiw-item__headericon {
			display: flex;
			width: 100%;
			flex: 0 0 100%;
			margin: 0 0 15px 0;
			align-items: center;
			justify-content: center;

			.hostjane-hiw-item__icon {
				width: 150px;
			}
		}
		.hostjane-hiw-item__chevvy {
			display: none;
		}

		.hostjane-hiw-item__title {
			width: calc(100% - 61px);
			flex: 0 0 calc(100% - 61px);
			font-weight: 800;
		}

		.hostjane-hiw-item__body {
			display: flex;
			padding-left: 61px;

			.hostjane-hiw-item__icon {
				display: none;
			}
		}
	}

	@media screen and (max-width: 1023px) {
		.hostjane-hiw-item__body {
			// display: flex;
			flex-flow: initial;
			padding-left: 0;
			margin-top: 10px;

			.hostjane-hiw-item__icon {
				margin: 0;
				transform: none;
				width: 190px;
			}
			.hostjane-hiw-item__link {
				display: inline-flex;
			}
			.hostjane-hiw-item__content-wrapper {
				margin-left: 20px;
			}
			.hostjane-hiw-item__content {
				line-height: 1.5;
				padding-bottom: 0px;
			}
		}
	}

	@media screen and (max-width: 500px) {
		.hostjane-hiw-item__body {
			.hostjane-hiw-item__content {
				padding-bottom: 0px;
			}
		}
	}
`;

export function HIWItem({
	index,
	title,
	children,
	icon: Icon = null,
	link = null,
	linkLabel = null,
	internalLink = false,
	fullWidth = false,
	hasFlame = false,
}) {
	const [open, setOpen] = useState(false);
	console.log('open >>>', open);
	return (
		<SingleItemContainer
			className="hostjane-hiw-item"
			fullWidth={fullWidth}
		>
			<div
				className="hostjane-hiw-item__header"
				onClick={() => {
					setOpen(o => !o);
				}}
				tabIndex={0}
				onKeyDown={e => {
					if (e.keyCode === 32 || e.keyCode === 13) {
						setOpen(o => !o);
					}
				}}
				role="button"
			>
				{Icon !== null ? (
					<div className="hostjane-hiw-item__headericon">
						<div className="hostjane-hiw-item__icon">
							<Icon />
						</div>
					</div>
				) : null}
				<div className="hostjane-hiw-item__count">
					{hasFlame ? <HostJaneIcon /> : index + 1}
				</div>
				<div className="hostjane-hiw-item__title">{title}</div>
				<div
					className={classNames('hostjane-hiw-item__chevvy', {
						'hostjane-hiw-item__chevvy--open': open,
					})}
				>
					<GenericIcon title={open ? 'Open' : 'Collapse'}>
						<ChevronDownIcon />
					</GenericIcon>
				</div>
			</div>
			<div
				className={classNames('hostjane-hiw-item__body', {
					'hostjane-hiw-item__body--open': open,
				})}
			>
				{Icon !== null ? (
					<div className="hostjane-hiw-item__icon">
						<Icon />
					</div>
				) : null}
				<div className="hostjane-hiw-item__content-wrapper">
					<div className="hostjane-hiw-item__content">{children}</div>
					{link && linkLabel ? (
						internalLink ? (
							<Link to={link} className="hostjane-hiw-item__link">
								{linkLabel}
							</Link>
						) : (
							<a href={link} className="hostjane-hiw-item__link">
								{linkLabel}
							</a>
						)
					) : null}
				</div>
			</div>
		</SingleItemContainer>
	);
}

const PaymentShieldContainer = styled.div`
	border: 1px solid #d5dbdb;
	padding: 15px;
	line-height: 1.6;
	font-size: 16px;
	display: flex;
	flex-flow: row nowrap;
	margin: 20px 0;
	@media screen and (min-width: 1024px) {
		margin-bottom: 0;
	}
	align-items: center;
	.hostjane-paymentshield__icon {
		height: 32px;
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 15px 0 0;
		flex: 0 0 32px;
		svg {
			height: 32px;
			width: 32px;
		}
	}
	.hostjane-paymentshield__content {
		flex: 0 0 calc(100% - 47px);
		color: #0052cc;
	}
`;
export function PaymentShield({ title, children }) {
	return (
		<PaymentShieldContainer className="hostjane-paymentshield">
			<div className="hostjane-paymentshield__icon">
				<PaymentShieldIcon />
			</div>
			<div className="hostjane-paymentshield__content">
				<strong>{title}</strong>: {children}
			</div>
		</PaymentShieldContainer>
	);
}

export const SmallFooterParagraph = styled.p`
	margin: 0 10px;
	font-size: 16px;
	border-top: ${props => (props.noBorder ? '' : '1px solid #d4d9dc')};
	padding: 20px 10px 10px;
	line-height: 1.6;
`;

export const SmallBorderedParagraph = styled.p`
	font-size: 18px;
	margin-top: 0;
	padding-bottom: ${props => (props.paddingBottom ? '14px' : '')};
	&:last-child {
		border-bottom: 0 none;
		margin-bottom: 0;
	}
	@media screen and (min-width: 1024px) {
		border-bottom: ${props => (props.noBorder ? '' : '1px solid #edeef2')};
	}
`;
