// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-all-components-index-js": () => import("./../../../src/pages/all-components/index.js" /* webpackChunkName: "component---src-pages-all-components-index-js" */),
  "component---src-pages-cloud-index-js": () => import("./../../../src/pages/cloud/index.js" /* webpackChunkName: "component---src-pages-cloud-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vps-hosting-index-js": () => import("./../../../src/pages/vps-hosting/index.js" /* webpackChunkName: "component---src-pages-vps-hosting-index-js" */),
  "component---src-pages-vps-index-js": () => import("./../../../src/pages/vps/index.js" /* webpackChunkName: "component---src-pages-vps-index-js" */),
  "component---src-pages-windows-index-js": () => import("./../../../src/pages/windows/index.js" /* webpackChunkName: "component---src-pages-windows-index-js" */),
  "component---src-pages-wordpress-index-js": () => import("./../../../src/pages/wordpress/index.js" /* webpackChunkName: "component---src-pages-wordpress-index-js" */)
}

